"use client";
import { XIcon } from "lucide-react";
import React from "react";
import { ToastContainer } from "react-toastify";
import { useMediaQuery } from "usehooks-ts";

import IconButton from "components/IconButton";
import { matter } from "app/fonts";

const LoginLayout = ({ children }: { children: React.ReactNode }) => {
  const isMobile = useMediaQuery("(max-width: 1023px)");

  return (
    <>
      <ToastContainer
        closeButton={<IconButton icon={XIcon} color="transparent" size="xs" className="m-1" />}
        position={isMobile ? "bottom-center" : "top-right"}
        hideProgressBar
      />
      <div className={`${matter.className}`}>
        <div className="align-center flex h-screen w-full flex-1 flex-col items-center justify-between bg-[#FBFBF6] px-4 py-12 sm:px-6 lg:px-8">
          {children}
        </div>
      </div>
    </>
  );
};

export default LoginLayout;
