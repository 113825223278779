import {
  AppWindow,
  Banknote,
  Bath,
  Book,
  Bot,
  Brain,
  Briefcase,
  Building,
  Calculator,
  CalendarDays,
  CheckCircle,
  CircleDashed,
  Clipboard,
  ClipboardCopy,
  Coins,
  Compass,
  Construction,
  Cpu,
  Copy,
  Crosshair,
  DollarSign,
  ExternalLink,
  FileStack,
  Files,
  Filter,
  Flame,
  FlaskRound,
  Folder,
  Gauge,
  Hammer,
  HardHat,
  HelpCircle,
  Home,
  Image,
  Import,
  Instagram,
  Keyboard,
  LifeBuoy,
  Lightbulb,
  List,
  Loader,
  LucideIcon,
  Mail,
  MapPin,
  MessageSquare,
  Package,
  Paintbrush2,
  Palette,
  PenLine,
  PhoneForwarded,
  PlayCircle,
  Quote,
  Receipt,
  RefreshCcw,
  ScrollText,
  Search,
  Server,
  Settings,
  ShoppingBag,
  SkipForward,
  Smartphone,
  Snowflake,
  Star,
  StarHalf,
  StickyNote,
  Table,
  Tag,
  Tags,
  Trophy,
  Twitter,
  UserSquare,
  Users2,
  Vibrate,
  Video,
  Voicemail,
  Wallet,
  WalletCards,
  Zap,
  Unlock,
  FileText,
  Plus,
  ArrowRight,
  Bookmark,
  Trash2,
  Trash,
  Pin,
  PinOff,
  FolderPen,
  ArrowUpNarrowWide,
  ArrowDownWideNarrow
} from "lucide-react";

export const NAVIGATION_ICONS_MAP: Record<string, LucideIcon> = {
  "circle-dashed": CircleDashed,
  "map-pin": MapPin,
  "file-stack": FileStack,
  receipt: Receipt,
  hammer: Hammer,
  bot: Bot,
  briefcase: Briefcase,
  "flask-round": FlaskRound,
  vibrate: Vibrate,
  construction: Construction,
  keyboard: Keyboard,
  quote: Quote,
  flame: Flame,
  crosshair: Crosshair,
  "users-2": Users2,
  table: Table,
  "calendar-days": CalendarDays,
  "shopping-bag": ShoppingBag,
  zap: Zap,
  loader: Loader,
  palette: Palette,
  banknote: Banknote,
  "scroll-text": ScrollText,
  smartphone: Smartphone,
  building: Building,
  "dollar-sign": DollarSign,
  "hard-hat": HardHat,
  "phone-forwarded": PhoneForwarded,
  bath: Bath,
  wallet: Wallet,
  voicemail: Voicemail,
  cpu: Cpu,
  list: List,
  "help-circle": HelpCircle,
  "clipboard-copy": ClipboardCopy,
  clipboard: Clipboard,
  snowflake: Snowflake,
  star: Star,
  lightbulb: Lightbulb,
  "star-half": StarHalf,
  mail: Mail,
  instagram: Instagram,
  "life-buoy": LifeBuoy,
  compass: Compass,
  "check-circle": CheckCircle,
  book: Book,
  "user-square": UserSquare,
  "paintbrush-2": Paintbrush2,
  package: Package,
  tags: Tags,
  search: Search,
  home: Home,
  "messages-square": MessageSquare,
  gauge: Gauge,
  filter: Filter,
  settings: Settings,
  image: Image,
  "skip-forward": SkipForward,
  files: Files,
  video: Video,
  trophy: Trophy,
  twitter: Twitter,
  "refresh-ccw": RefreshCcw,
  "external-link": ExternalLink,
  "sticky-note": StickyNote,
  server: Server,
  brain: Brain,
  coins: Coins,
  tag: Tag,
  "play-circle": PlayCircle,
  calculator: Calculator,
  import: Import,
  "pen-line": PenLine,
  "wallet-cards": WalletCards,
  folder: Folder,
  "app-window": AppWindow,
  unlock: Unlock,
  "file-text": FileText,
  plus: Plus,
  "arrow-right": ArrowRight,
  bookmark: Bookmark,
  "trash-2": Trash2,
  trash: Trash,
  pin: Pin,
  "pin-off": PinOff,
  "folder-pen": FolderPen,
  copy: Copy,
  "arrow-up-narrow-wide": ArrowUpNarrowWide,
  "arrow-down-wide-narrow": ArrowDownWideNarrow
};
