"use client";
import { LucideProps } from "lucide-react";
import dynamicIconImports from "lucide-react/dynamicIconImports";
import dynamic from "next/dynamic";
import React, { useMemo } from "react";
import { NAVIGATION_ICONS_MAP } from "./utils";

export interface IconProps extends Omit<LucideProps, "ref"> {
  name: keyof typeof dynamicIconImports;
}

const NAVIGATION_ICONS_LIST = Object.keys(NAVIGATION_ICONS_MAP);

const Icon = ({ name, ...props }: IconProps) => {
  const IconComp = useMemo(
    () =>
      NAVIGATION_ICONS_LIST.includes(name)
        ? NAVIGATION_ICONS_MAP[name]
        : dynamic(dynamicIconImports[name], {
            loading: ({ isLoading }) => (isLoading ? <div className={props.className} /> : null)
          }),
    [name, props.className]
  );

  return <IconComp {...props} />;
};

export default React.memo(Icon);
