import React from "react";
import clsx from "clsx";
import { LucideIcon } from "lucide-react";

import IconComp from "components/Icon";
import { IconButtonSize, IconButtonColor } from "./utils";

type Icon = LucideIcon | React.FunctionComponent<{ className: string }>;
type IconButtonProps = {
  size?: string;
  color?: string;
  icon?: Icon | null;
  disabled?: boolean;
  onClick?: (e: any) => void;
  className?: string;
  classNameIcon?: string;
  type?: "button" | "submit" | "reset";
  mRef?: React.RefObject<HTMLButtonElement> | null;
  ref?: React.RefObject<any> | null;
  tag?: React.ElementType;
  href?: string;
  target?: string;
  rel?: string;
  role?: string;
  title?: string;
  tabIndex?: number;
  onMouseDown?: (e: any) => void;
  iconName?: string;
  count?: number;
};

const IconButton = React.forwardRef(
  (
    {
      size = IconButtonSize.SM,
      icon = null,
      iconName = "",
      disabled = false,
      className = "",
      classNameIcon = "",
      color = IconButtonColor.WHITE,
      onClick = () => {},
      mRef = null,
      type,
      title,
      tag: Tag = "button",
      count,
      ...rest
    }: IconButtonProps,
    ref: React.Ref<HTMLButtonElement>
  ) => {
    const clsButtonSize = {
      [IconButtonSize.XS]: "h-6 w-6",
      [IconButtonSize.SM]: "h-8 w-8",
      [IconButtonSize.MD]: "h-11 w-11"
    };
    const clsIconSize = {
      [IconButtonSize.XS]: "h-3 w-3",
      [IconButtonSize.SM]: "h-4 w-4",
      [IconButtonSize.MD]: "h-5 w-5"
    };
    const clsButtonColor = {
      [IconButtonColor.PRIMARY]: clsx(
        "text-sm font-medium transition-colors bg-primary-700 text-neutral-0",
        "hover:bg-primary-800 active:bg-primary-900"
      ),
      [IconButtonColor.SECONDARY]: clsx(
        "text-primary border border-neutral-300 hover:bg-neutral-200 active:bg-neutral-300",
        "dark:border-neutral-dark-300 dark:hover:bg-neutral-dark-200 dark:active:bg-neutral-dark-300"
      ),
      [IconButtonColor.RED]: "text-neutral-0 bg-red-700 hover:bg-red-800 active:bg-red-900",
      [IconButtonColor.WHITE]: disabled
        ? " border border-separator"
        : " hover:bg-neutral-100 active:bg-neutral-200 border border-gray-200  hover:bg-gray-200 dark:border-gray-700 dark:hover:bg-gray-700 scale-100 transition-transform active:scale-[0.98 text-icons",
      [IconButtonColor.LIGHT]: disabled
        ? ""
        : "bg-gray-100 transition-all hover:scale-105 hover:bg-gray-200 active:scale-95 dark:bg-gray-700 dark:hover:bg-gray-600",
      [IconButtonColor.TRANSPARENT]:
        "bg-transparent hover:bg-neutral-200 dark:hover:bg-gray-750 active:bg-neutral-300 dark:active:bg-neutral-dark-300 text-icons"
    };
    const clsIconColor = {
      [IconButtonColor.WHITE]: disabled ? "text-base-disabled" : "text-icons",
      [IconButtonColor.LIGHT]: disabled ? "text-neutral-300" : "text-icons",
      [IconButtonColor.TRANSPARENT]: disabled ? "text-neutral-300" : "text-icons",
      [IconButtonColor.PRIMARY]: ""
    };

    const IconComponent = iconName ? IconComp : (icon as Icon);

    return (
      <Tag
        data-testid="IconButton"
        className={clsx(
          "relative inline-flex shrink-0 items-center justify-center rounded-lg",
          disabled ? "cursor-default" : "cursor-pointer",
          clsButtonColor[color],
          clsButtonSize[size],
          className,
          disabled && "!bg-neutral-200 !text-base-disabled dark:!bg-neutral-dark-200 dark:!text-base-dark-disabled"
        )}
        onClick={onClick}
        ref={mRef || ref}
        type={type}
        title={title}
        disabled={disabled}
        {...rest}
      >
        {(icon || iconName) && (
          <IconComponent
            name={iconName as any}
            className={clsx(clsIconSize[size], clsIconColor[color], classNameIcon)}
          />
        )}
        {!!count && (
          <span
            className={clsx(
              "absolute right-[-5px] top-[-5px] flex h-3.5  w-3.5 items-center justify-center rounded-full bg-red-700 text-2xs font-semibold text-white dark:bg-red-dark-700"
            )}
          >
            {count}
          </span>
        )}
      </Tag>
    );
  }
);

IconButton.displayName = "IconButton";

export default IconButton;
